<template>
    <nav
        class="fixed h-screen bg-primary-700 flex flex-col z-50 w-[--sidebar-size-collapsed] hover:w-[300px] group/navbar text-primary-300 text-md font-medium"
        @mouseover="isOpen = true"
        @mouseleave="isOpen = false"
    >
        <!-- LOGO -->
        <div class="bg-white">
            <img
                class="h-[78px] p-[23px] box-border hidden group-hover/navbar:inline"
                src="/src/assets/icons/logo.svg"
            />
            <img
                class="h-[78px] p-[23px] box-border group-hover/navbar:hidden"
                src="/src/assets/icons/logo_collapsed.svg"
            />
        </div>

        <div class="h-full flex flex-col pt-2.5 pb-4 px-4">
            <!-- MAIN NAVIGATION -->
            <ul
                class="flex flex-col justify-between gap-2"
                v-if="currentUser?.is_active"
            >
                <NavItem
                    :to="{ name: 'documents-list' }"
                    icon-type="file"
                    label="Documents"
                />

                <!-- Configuration sub-menu -->
                <li
                    class="p-3 rounded-md transition-colors duration-200"
                    :class="
                        isConfigurationLinkActive
                            ? 'bg-primary-800'
                            : 'hover:bg-primary-800 group/configuration'
                    "
                    v-if="isSuperAdmin"
                >
                    <div class="flex flex-row gap-4">
                        <VueFeather
                            size="24px"
                            type="settings"
                        />
                        <div
                            class="hidden group-hover/navbar:flex flex-grow justify-between"
                        >
                            <span> Configuration </span>
                            <VueFeather
                                type="chevron-down"
                                size="24px"
                                class="group-hover/configuration:-rotate-180 transition-all duration-200 ease-in-out origin-center"
                            />
                        </div>
                    </div>
                    <ul
                        class="text-sm hidden flex-col items-start gap-4 mt-4 ml-10 [&>li:hover]:text-primary-400"
                        :class="
                            isConfigurationLinkActive
                                ? 'group-hover/navbar:flex'
                                : 'group-hover/configuration:!flex'
                        "
                    >
                        <li>
                            <RouterLink
                                :to="{ name: 'teams' }"
                                active-class="text-primary-400"
                            >
                                Team
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink
                                :to="{ name: 'configuration' }"
                                active-class="text-primary-400"
                            >
                                Organization configuration
                            </RouterLink>
                        </li>
                    </ul>
                </li>
                <!--  -->
            </ul>

            <!-- USER AND ORGANIZATION DETAILS -->
            <template v-if="isRegistrationComplete">
                <hr class="border-primary-600 mt-auto" />

                <hr class="border-primary-600" />
                <div class="p-3 flex flex-row items-center gap-4 group/org">
                    <VueFeather
                        size="24px"
                        :class="{ 'group-hover/org:hidden': isSuperAdmin }"
                        type="folder"
                    />
                    <VueFeather
                        size="24px"
                        type="folder-plus"
                        class="!hidden group-hover/org:!flex cursor-pointer"
                        data-tooltip="Create new organization"
                        data-tooltip-position="right"
                        @click="showNewOrganizationModal = true"
                        v-if="isSuperAdmin"
                    />
                    <OrganizationList
                        class="hidden group-hover/navbar:flex flex-grow -my-1"
                    />
                </div>

                <div class="p-3 flex flex-row items-center gap-4">
                    <VueFeather
                        size="24px"
                        type="user"
                    />
                    <span
                        class="hidden group-hover/navbar:flex justify-between flex-grow items-center"
                    >
                        {{ currentUser?.first_name || currentUser?.email }}
                        <EaiBadge
                            v-if="userRole"
                            :text="userRole"
                            colour="primary"
                        />
                    </span>
                </div>

                <EaiButton
                    class="ml-auto mt-2 invisible group-hover/navbar:visible"
                    size="small"
                    type="secondary"
                    @click="emit('logout')"
                >
                    Logout
                    <VueFeather
                        type="log-out"
                        size="20px"
                    />
                </EaiButton>
            </template>
        </div>

        <EaiModal v-if="isSuperAdmin && showNewOrganizationModal">
            <template #header>Create new organization</template>
            <template #body>
                <OrganizationCreation
                    @organization-created="
                        () => {
                            showNewOrganizationModal = false
                            authStore.retrieveUser()
                        }
                    "
                />
            </template>
            <template #actions>
                <EaiButton
                    type="secondary"
                    class="w-full"
                    @click="showNewOrganizationModal = false"
                >
                    Cancel
                </EaiButton>
            </template>
        </EaiModal>
    </nav>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { storeToRefs } from "pinia"
import { useAuthStore } from "/src/stores/auth"
import EaiButton from "/src/components/core/EaiButton.vue"
import EaiBadge from "/src/components/core/EaiBadge.vue"
import NavItem from "./NavItem.vue"
import { useRoute } from "vue-router"
import OrganizationList from "/src/components/organization/OrganizationList.vue"
import OrganizationCreation from "/src/components/organization/OrganizationCreation.vue"
import EaiModal from "../EaiModal.vue"

const emit = defineEmits<{ (e: "logout"): void }>()

const authStore = useAuthStore()
const {
    currentUser,
    currentUserRole,
    currentOrganization,
    isRegistrationComplete,
    isSuperAdmin,
} = storeToRefs(authStore)

const userRole = computed(() => {
    if (currentOrganization.value) {
        if (currentUserRole.value) return currentUserRole.value
        else if (isSuperAdmin.value) return "superadmin"
    }
    return undefined
})

const route = useRoute()
const isConfigurationLinkActive = computed(() => {
    return route.meta?.parentNavbarSection === "configuration"
})

// Note, this is only necessary because of the organization selection dropup
const isOpen = ref(false)

const showNewOrganizationModal = ref(false)
</script>
