<template>
    <Teleport to="body">
        <!-- TODO: set a reasonable z (need to update sidebar) -->
        <div
            class="fixed w-full h-full z-[1000] backdrop-blur bg-gray-700/60 flex flex-col items-center justify-center"
        >
            <div
                class="w-[400px] bg-white rounded-xl p-6 flex flex-col justify-start gap-8"
                v-bind="$attrs"
            >
                <div class="flex flex-col gap-5">
                    <div class="text-gray-900 text-lg font-medium">
                        <slot name="header"></slot>
                    </div>
                    <div class="text-gray-500 text-sm font-normal">
                        <slot name="body"></slot>
                    </div>
                </div>
                <div class="flex flex-row gap-3 justify-end">
                    <slot name="actions"></slot>
                </div>
            </div>
        </div>
    </Teleport>
</template>
