<template>
    <form
        ref="inputForm"
        class="flex flex-col gap-6"
        @submit.prevent.stop
    >
        <EaiInput
            ref="nameInput"
            v-model="organizationName"
            required
            placeholder="Enter a name for your organization"
            label="Name*"
        >
        </EaiInput>
        <EaiButton
            type="primary"
            size="large"
            @click="validateAndCreate()"
        >
            Create
        </EaiButton>
    </form>
</template>

<script setup lang="ts">
import { ref } from "vue"
import EaiInput from "../core/input/EaiInput.vue"
import EaiButton from "../core/EaiButton.vue"
import { apiClient, HTTPError } from "/src/http/http"

const organizationName = ref("")
const inputForm = ref<HTMLFormElement | null>(null)
const nameInput = ref<typeof EaiInput | null>(null)

const emit = defineEmits<{
    (e: "organization-created"): void
}>()

const validateAndCreate = async () => {
    if (!(nameInput.value && inputForm.value))
        throw "Input component not initialised"

    inputForm.value.checkValidity()

    nameInput.value.setErrorMessage("")
    try {
        await apiClient.post("/organizations", {
            payload: {
                name: organizationName.value,
            },
        })
    } catch (error) {
        let errorMessage = "Invalid input"
        if (error instanceof HTTPError) {
            if (error.code === 409)
                errorMessage =
                    "The selected name has already been taken, please choose a different one."
            else if (error.message) errorMessage = error.message
        }
        nameInput.value.setErrorMessage(errorMessage, true)
        return
    }

    alert("Organization created")
    emit("organization-created")
}
</script>
