<template>
    <EaiSelect
        size="small"
        :searchable="currentUserOrganizations?.length > 5"
        :options="organizationOptions"
        :model-value="currentOrganization.id"
        @change="authStore.changeOrganization($event)"
        dropup
        :disabled="currentUserOrganizations?.length <= 1"
    />
</template>

<script setup lang="ts">
import { useAuthStore } from "/src/stores/auth"
import { storeToRefs } from "pinia"
import EaiSelect from "/src/components/core/EaiSelect.vue"
import { computed } from "vue"

const authStore = useAuthStore()
const { currentUserOrganizations, currentOrganization } = storeToRefs(authStore)

const organizationOptions = computed(() => {
    return (
        currentUserOrganizations.value?.map((organization) => ({
            label: organization.name,
            value: organization.id,
        })) || []
    )
})
</script>
