type HttpMethod = "GET" | "POST" | "PUT" | "DELETE"

export class HTTPError extends Error {
    name: string
    code: number
    message: string
    payload: { [key: string]: any } | null

    constructor(response: Response, jsonPayload?: { [key: string]: any }) {
        super(response.statusText)
        this.name = "HTTPError"
        this.code = response.status
        this.message = jsonPayload?.message || response.statusText
        this.payload = jsonPayload || null
    }
}

// Client for internal API calls. Base URL is defined in .env files.
const apiClient = async (
    method: HttpMethod,
    endpoint: string,
    {
        payload = null,
        params = null,
        headers = null,
    }: {
        payload?: any
        params?: { [key: string]: string | number | boolean } | null
        headers?: { [key: string]: string } | null
    } = {
        payload: null,
        params: null,
        headers: null,
    }
) => {
    const baseURL = import.meta.env.VITE_API_BASEURL || window.location.origin

    if (!endpoint.startsWith("/")) endpoint = "/" + endpoint

    const url = new URL(import.meta.env.VITE_API_PREFIX + endpoint, baseURL)

    // Headers config
    if (headers === null) headers = {}

    // Add CSRF token to state-changing request (if available)
    const csrfToken = getCsrfFromCookie()
    if (csrfToken && method !== "GET") headers["X-CSRF-TOKEN"] = csrfToken

    let req: RequestInit = {
        method,
        headers: Object.assign({}, apiClient.headers, headers ?? {}),
        mode: "cors",
        credentials: "include",
    }

    if (params != null)
        Object.entries(params).forEach(([k, v]) => {
            if (v) url.searchParams.append(k, v)
        })

    if (payload != null) req["body"] = JSON.stringify(payload)

    return fetch(url, req).then(async (response) => {
        if (!response.ok) {
            let jsonPayload: { [k: string]: any }
            try {
                jsonPayload = await response.json()
            } catch {
                jsonPayload = {}
            }

            throw new HTTPError(response, jsonPayload)
        } else if (
            response.headers.get("Content-Type")?.includes("application/json")
        ) {
            return response.json()
        } else {
            return response.text()
        }
    })
}

/** Retrieve the CSRF Token from the cookie.
 *
 * The name should match the `JWT_ACCESS_CSRF_COOKIE_NAME` const in the relevant Flask
 * config file (e.g. instance/default.py, instance/prod.py, ...).
 *
 * See https://flask-jwt-extended.readthedocs.io/en/stable/token_locations.html#cookies
 * for details.
 *
 */
export const getCsrfFromCookie = (name: string = "csrf_access_token") => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts && parts.length === 2) return parts.pop()?.split(";").shift()
    return null
}

apiClient.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
}

apiClient.get = apiClient.bind(null, "GET")
apiClient.post = apiClient.bind(null, "POST")
apiClient.put = apiClient.bind(null, "PUT")
apiClient.delete = apiClient.bind(null, "DELETE")

export { apiClient }
